<template>
  <div
    id="asideLayout"
    :style="{
      paddingTop: `${paddingAside}px`,
    }"
  >
    <AsideMenu />
  </div>
</template>

<script>
import AsideMenu from "@/views/layout/AsideLayout/AsideMenu.vue";

export default {
  name: "AsideLayout",
  components: { AsideMenu },
  data() {
    return {
      paddingAside: null,
    };
  },
  mounted() {
    this.paddingAside = document.querySelector("#headerLayout").clientHeight;
  },
};
</script>

<style lang="scss">
#asideLayout {
  width: 15%;
  background-color: #3f4e66;
  color: white;
}
</style>
