<template>
  <div id="contentLayout">
    <div class="container-fluid h-100 py-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentLayout",
};
</script>

<style>
#contentLayout {
  background-color: #dee3e8;
  flex: 1;
}
</style>
