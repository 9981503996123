<template>
  <ul id="aside_menu" class="py-2 px-0">
    <router-link to="/dashboard" class="py-2" v-slot="{ href, navigate, isActive, isExactActive }">
      <li
        class="text-decoration-none pl-4 menu-item"
        :class="[
          isActive && 'text-warning menu-item-active',
          isExactActive && 'text-warning menu-item-active',
        ]"
      >
        <a :href="href" @click="navigate">
          <span
            class="text-white"
            :class="[isActive && 'text-primary', isExactActive && 'text-primary']"
            >{{ $t("MENU.DASHBOARD") }}</span
          >
        </a>
      </li>
    </router-link>
    <router-link
      to="/roles-list"
      class="py-2"
      v-slot="{ href, route, navigate, isActive, isExactActive }"
    >
      <li
        class="text-decoration-none pl-4 menu-item"
        :class="[
          isActive && 'text-warning menu-item-active',
          isExactActive && 'text-warning menu-item-active',
          hasActiveChildren('roles') && 'text-primary menu-item-active',
        ]"
      >
        <a :href="href" @click="navigate">
          {{ $t("MENU.ROLES") }}
        </a>
      </li>
    </router-link>
    <router-link
      to="/users-list"
      class="py-2"
      v-slot="{ href, route, navigate, isActive, isExactActive }"
    >
      <li
        class="text-decoration-none pl-4 menu-item"
        :class="[
          isActive && 'text-warning menu-item-active',
          isExactActive && 'text-warning menu-item-active',
          hasActiveChildren('users') && 'text-primary menu-item-active',
        ]"
      >
        <a :href="href" @click="navigate">
          {{ $t("MENU.USERS") }}
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: "AsideMenu",
  methods: {
    hasActiveChildren(match) {
      return this.$route["meta"].parent === match;
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style lang="scss">
#aside_menu {
  * {
    list-style: none;
  }
}

.menu-item {
  position: relative;
  color: white;
  a {
    color: white;
  }
  &-active {
    background-color: lighten($primary, 50%);
    &:after {
      content: "";
      position: absolute;
      width: 3px;
      height: 100%;
      background-color: $primary;
      top: 0;
      left: 0;
    }
    a {
      color: $primary;
    }
  }
}
</style>
