<template>
  <footer id="footerLayout" class="px-2">
    <small class="text-muted">Wszelkie prawa zastrzeżone. Powered by Evipstudio.pl</small>
  </footer>
</template>

<script>
export default {
  name: "FooterLayout",
};
</script>

<style lang="scss">
#footerLayout {
}
</style>
