<template>
  <div class="d-flex align-items-end">
    <BBreadcrumb class="h-auto my-1">
      <BBreadcrumbItem>EVIP CRM</BBreadcrumbItem>
      <BBreadcrumbItem>Dashboard</BBreadcrumbItem>
      <BBreadcrumbItem>Role dostepowe</BBreadcrumbItem>
    </BBreadcrumb>
  </div>
</template>

<script>
export default {
  name: "BreadcrumbLayout",
};
</script>
