<template>
  <div id="offlineAlert" v-if="!isOnline" class="position-fixed vh-100 vw-100 p-4">
    <b-alert show variant="danger" class="">
      <h4 class="alert-heading font-weight-bold">Jesteś offline!</h4>
      <p>
        Wykryliśmy, że nie masz połączenia z internetem. Do prawidłowego działania systemu CRM
        wymagane jest połączenie z siecią. Odśwież stronę lub skontaktuj się z administratorem
      </p>
      <hr />
      <p>Mail: <b>biuro@evipstudio.pl</b></p>
    </b-alert>

    <b-button variant="success" size="xl" class="text-white w-100" @click="reloadPage">
      Odśwież stronę</b-button
    >
  </div>
</template>

<script>
export default {
  name: "OfflineAlert",
  methods: {
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>

<style>
#offlineAlert {
  background-color: rgba(255, 255, 255, 0.95);
  z-index: 9999;
}
</style>
