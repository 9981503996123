var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"py-2 px-0",attrs:{"id":"aside_menu"}},[_c('router-link',{staticClass:"py-2",attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"text-decoration-none pl-4 menu-item",class:[
        isActive && 'text-warning menu-item-active',
        isExactActive && 'text-warning menu-item-active' ]},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"text-white",class:[isActive && 'text-primary', isExactActive && 'text-primary']},[_vm._v(_vm._s(_vm.$t("MENU.DASHBOARD")))])])])]}}])}),_c('router-link',{staticClass:"py-2",attrs:{"to":"/roles-list"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var route = ref.route;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"text-decoration-none pl-4 menu-item",class:[
        isActive && 'text-warning menu-item-active',
        isExactActive && 'text-warning menu-item-active',
        _vm.hasActiveChildren('roles') && 'text-primary menu-item-active' ]},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("MENU.ROLES"))+" ")])])]}}])}),_c('router-link',{staticClass:"py-2",attrs:{"to":"/users-list"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var route = ref.route;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"text-decoration-none pl-4 menu-item",class:[
        isActive && 'text-warning menu-item-active',
        isExactActive && 'text-warning menu-item-active',
        _vm.hasActiveChildren('users') && 'text-primary menu-item-active' ]},[_c('a',{attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("MENU.USERS"))+" ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }