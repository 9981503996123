<template>
  <div id="headerLayout" ref="headerLayout">
    <b-navbar toggleable="lg" type="dark">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown :text="$i18n.locale.toLocaleUpperCase()" right>
            <b-dropdown-item
              href="#"
              @click="$i18n.locale = lang"
              v-for="lang in langs"
              :key="lang"
              v-show="$i18n.locale !== lang"
            >
              {{ lang.toUpperCase() }}</b-dropdown-item
            >
          </b-nav-item-dropdown>

          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content> {{ currentUser }} </template>
            <b-dropdown-item href="#">{{ $t("USER.PROFILE") }}</b-dropdown-item>
            <b-dropdown-item href="#" @click="logout"> {{ $t("USER.SIGNOUT") }}</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/store/auth.module";

export default {
  name: "HeaderLayout",
  data() {
    return {
      langs: ["pl", "en"],
    };
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        this.$router.push({ name: "login" });
      });
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>

<style lang="scss">
#headerLayout {
  background-color: #f8f9fd;
  * {
    color: black !important;
  }
}
</style>
