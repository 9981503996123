<template>
  <div v-if="isAuthenticated" id="layout" class="d-flex">
    <OfflineAlert />
    <AsideLayout />
    <div class="wrapper w-100 d-flex flex-column">
      <HeaderLayout />
      <BreadcrumbLayout />
      <ContentLayout>
        <router-view />
      </ContentLayout>
      <FooterLayout />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AsideLayout from "@/views/layout/AsideLayout/AsideLayout.vue";
import FooterLayout from "@/views/layout/FooterLayout/FooterLayout.vue";
import HeaderLayout from "@/views/layout/HeaderLayout/HeaderLayout.vue";
import ContentLayout from "@/views/layout/ContentLayout/ContentLayout.vue";
import BreadcrumbLayout from "@/views/layout/BreadcrumbLayout/BreadcrumbLayout.vue";
import { PERMISSIONS } from "@/core/store/auth.module";
import OfflineAlert from "@/components/OfflineAlert/OfflineAlert.vue";

export default {
  name: "Layout",
  components: {
    OfflineAlert,
    BreadcrumbLayout,
    ContentLayout,
    HeaderLayout,
    FooterLayout,
    AsideLayout,
  },
  beforeMount() {
    // show page loading
    // initialize html element classes
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push("/login");
    }
    this.$store.dispatch(PERMISSIONS);
  },
  computed: {
    ...mapGetters(["isAuthenticated", "getPermissionsList"]),
  },
};
</script>

<style lang="scss">
#layout {
  min-height: 100vh;
}
</style>
